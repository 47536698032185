import React from 'react';
import * as Redux from 'redux'
import { connect } from 'react-redux'

import { ActionTypes } from './ReducerTypes';
import { changeMap, openFrame } from './ReducerActions';

import { State } from './ReducerTypes';


import './WallOfCards.css';

interface IProps {

}

interface DispatchProps {
    changeMap: (url: string) => void;
    openFrame: (url: string) => void;
}

interface StateProps {
    
}

type Props = StateProps & DispatchProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
        
    };
}

const mapDispatchToProps = {

    changeMap,
    openFrame
};

interface IState {

  postcards: any[],
  movingCard: any,
  fullscreen: boolean,
  fullscreenTipStep: number,
  dimensions: number[]
}

class WallOfCards extends React.Component <Props,IState> {

  private originX: number;
  private originY: number;
  private x: number;
  private y: number;
  private mouseMoved: boolean;
  private actionHistory: number[];

  private dimMax: number;

  constructor(props: Props) {

    super(props);

    this.startMove = this.startMove.bind(this);
    this.stopMove = this.stopMove.bind(this);
    this.continueMove = this.continueMove.bind(this);
    this.switchFullscreen = this.switchFullscreen.bind(this);
    this.resizeWall = this.resizeWall.bind(this);

    this.originX = 0;
    this.originY = 0;
    this.x = 0;
    this.y = 0;
    this.mouseMoved = false;
    this.actionHistory = [];

    if (window.innerWidth > window.innerHeight)
      this.dimMax = 200;
    else
      this.dimMax = 130;
    
    this.state = {

      postcards: [],
      movingCard: null,
      fullscreen: false,
      fullscreenTipStep: 0,
      dimensions: [window.innerWidth,window.innerHeight]
    };
  }

  componentDidMount () {

    this.getPostcards('postcards.json');

    setTimeout(() => {this.fullscreenTipAnimation()}, 5000);

    window.addEventListener("resize", this.resizeWall, false);
  }

  resizeWall() {

    //console.log("resizeWall");

    if (window.innerWidth > window.innerHeight)
      this.dimMax = 200;
    else
      this.dimMax = 130;

    this.setState({ dimensions: [window.innerWidth,window.innerHeight] });
  }

  fullscreenTipAnimation() {

    const durations = [2000,100,2000,2000];

    if (this.state.fullscreenTipStep < 4) {

      setTimeout(() => {this.fullscreenTipAnimation()}, durations[this.state.fullscreenTipStep]);

      this.setState({ fullscreenTipStep: this.state.fullscreenTipStep+1});
    }
    else {

      setTimeout(() => {this.fullscreenTipAnimation()}, 15000);

      this.setState({ fullscreenTipStep: 0});
    }
  }

  getPostcards(url: string) {

    fetch(url)
      .then(response => response.json())
      .then(response => {

          this.setState({postcards: response.postcards});

          for (let postcard of response.postcards) {

            if (postcard.default) {
              this.props.changeMap(postcard.action.map);
              break;
            }
          }
        })
      .catch(error => this.setState({ 
        postcards: []
      }));
  }

  computeDimensions(postcard: any) {

    let height, width;

    if (postcard.keepDimensions) {

      width = postcard.width;
      height = postcard.height;
    }
    else if (postcard.width >= postcard.height) {

      height = postcard.height * this.dimMax / postcard.width;
      width = this.dimMax;

      if (height > (0.5*this.dimMax)) {

        height = 0.5*this.dimMax;
        width = postcard.width * height / postcard.height;
      }
    }
    else {

      width = postcard.width * this.dimMax / postcard.height;
      height = this.dimMax;

      if (width > (0.5*this.dimMax)) {

        width = 0.5*this.dimMax;
        height = postcard.height * width / postcard.width;
      }
    }

    return [width,height];
  }

  startMove(e: any) {

    if (typeof e.changedTouches !== 'undefined') {

        this.originX = e.changedTouches[0].pageX;
        this.originY = e.changedTouches[0].pageY;
    }
    else {
      
      this.originX = e.clientX;
      this.originY = e.clientY;
    }

    this.setState({movingCard: e.currentTarget});

    const coordRegex = new RegExp("([0-9-.]+)px");
    
    let res = e.currentTarget.style.left.match(coordRegex);

    this.x = parseFloat(res[1]);

    res = e.currentTarget.style.top.match(coordRegex);

    this.y = parseFloat(res[1]);

    this.mouseMoved = false;

    e.stopPropagation();
    e.preventDefault();
  }

  stopMove(e: any, openPostCard: () => void) {

    if (this.state.movingCard) {

      this.setState({movingCard: null});

      if (!this.mouseMoved && openPostCard) {

        openPostCard();
      }

      e.stopPropagation();
      e.preventDefault();
    }
  }

  continueMove(e: any) {

    if (this.state.movingCard) {

      let newX, newY;

      if (typeof e.changedTouches !== 'undefined') {

        newX = e.changedTouches[0].pageX;
        newY = e.changedTouches[0].pageY;
      }
      else {
        
        newX = e.clientX;
        newY = e.clientY;
      }

      this.state.movingCard.style.left = (this.x+newX-this.originX)+"px";
      this.state.movingCard.style.top = (this.y+newY-this.originY)+"px";

      this.mouseMoved = true;

      e.stopPropagation();
      e.preventDefault();
    }
  }

  switchFullscreen(e: any) {

    for (let postcard of this.state.postcards) {

      postcard.posClass = "CardAnimated";
    }

    this.setState({postcards: this.state.postcards});

    if (!this.state.fullscreen) {

      setTimeout(() => {

        let i = 0;

        const coordRegex = new RegExp("([0-9-.]+)px");

        for (let postcard of this.state.postcards) {

          let top = 0;
          let left = 0;

          const el = document.getElementById("postcard"+i);

          let res = el!.style.top.match(coordRegex);

          if (res)
            top = parseFloat(res[1]);

          res = el!.style.left.match(coordRegex);

          if (res)
            left = parseFloat(res[1]);

          if ( (top <= (window.innerHeight*0.5)) && (left <= (window.innerWidth*0.5)) ) {

            postcard.posClass = "CardAnimated TopLeft";
          }
          else if ( (top <= (window.innerHeight*0.5)) && (left > (window.innerWidth*0.5)) ) {

            postcard.posClass = "CardAnimated TopRight";
          }
          else if ( (top > (window.innerHeight*0.5)) && (left <= (window.innerWidth*0.5)) ) {

            postcard.posClass = "CardAnimated BottomLeft";
          }
          else if ( (top > (window.innerHeight*0.5)) && (left > (window.innerWidth*0.5)) ) {

            postcard.posClass = "CardAnimated BottomRight";
          }

          i += 1;
        }

        this.setState({postcards: this.state.postcards, fullscreen: true});

      },10);
    }
    else {

      setTimeout(() => {

          for (let postcard of this.state.postcards) {

            postcard.posClass = "";
          }

          this.setState({postcards: this.state.postcards, fullscreen: false});
        },1500);
    }
  }

  performAction(action: any, delayed: boolean) {

    if (delayed && action.delay) {

      if (!action.delayOnce || (!this.actionHistory.includes(action.id))) {

        setTimeout(() => {this.performAction(action,false)},action.delay);
        return;
      }
    }

    if (action.id) {

      this.actionHistory.push(action.id);
    }

    if (action.map) {

      this.props.changeMap(action.map);
    }
    else if (action.wallofcards) {

      this.getPostcards(action.wallofcards);
    }
    else if (action.window) {

      const win = window.open(action.window, "_blank");
      win!.focus();
    }
    else if (action.iframe) {

      this.props.openFrame(action.iframe);
    }
    else if (action.display == "fullscreen") {

      this.switchFullscreen(null);
    }
  }

  renderPostcardContent(postcard: any, width: number, height: number) {

    if (postcard.img) {
      return (<img src={postcard.img} width={width} height={height}></img>);
    }
    else if (postcard.video) {

      if (window.innerWidth < 700) {

        return (<img width={width} height={height} src={postcard.video} />);
      }
      else {

        return (<video autoPlay loop width={width} height={height}>
                  <source src={postcard.video} type="video/mp4" />
                </video>);
      }
    }
    else if (postcard.desc) {

      return (<div style={{width:width,height:height}}>{postcard.desc}</div>);
    }
    else if (postcard.iframe) {

      return (<iframe style={{width:width,height:height}} src={postcard.iframe} />);
    }
  }

  renderPostcards(dimensions: number[]) {

    return this.state.postcards.map((postcard,index) => {

      let imgWidth, imgHeight;

      [imgWidth, imgHeight] = this.computeDimensions(postcard);

      let x, y;

      if (dimensions[0] >= dimensions[1]) {
        x = postcard.x;
        y = postcard.y*0.9;
      }
      else {
        x = postcard.y*0.9;
        y = postcard.x*0.9;
      }

      const cardStyle = {

        width: imgWidth+'px',
        height: imgHeight+'px',
        left: Math.floor(x*window.innerWidth)+'px',
        top: Math.floor(y*window.innerHeight)+'px',
        transform: 'rotateZ('+postcard.rotZ+'deg)',
      };

      const openPostCard = () => {

        if (postcard.action) {

          this.performAction(postcard.action,true);
        }
        else if (postcard.actions) {

          for (const action of postcard.actions) {

            this.performAction(action,true);
          }
        } 
      };
           
      return (<div key={index} id={"postcard"+index} className={'card cardVisible '+postcard.posClass} style={cardStyle} onMouseDown={this.startMove} onMouseUp={e => {this.stopMove(e, openPostCard)}} onMouseMove={this.continueMove} onTouchStart={this.startMove} onTouchEnd={e => {this.stopMove(e, openPostCard)}} onTouchMove={this.continueMove}><div className='cardShadows cardShadowsNear cardShadowsNearFar'><div className='cardShadowPath'></div></div><div className='cardBackground'>{this.renderPostcardContent(postcard,imgWidth,imgHeight)}<div className='cardTitle' style={{width:imgWidth+"px"}}>{postcard.title}</div></div></div>);
    });
  }
  
  render() {

    return (
      <div className={(this.state.movingCard) ? "WallOfCards" : "WallOfCards noPointerEvent"} onMouseMove={this.continueMove} onTouchMove={this.continueMove} onMouseUp={e => {this.stopMove(e, () => {})}} onTouchEnd={e => {this.stopMove(e, () => {})}}>

        {this.renderPostcards(this.state.dimensions)}

        <div className={"fullscreen fullscreenStep"+this.state.fullscreenTipStep} onClick={this.switchFullscreen}/>

        <div className={"fullscreenTip fullscreenTipStep"+this.state.fullscreenTipStep}><span>Click here for entering/exiting fullscreen mode</span></div>

      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WallOfCards);