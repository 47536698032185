import React from 'react';
import './App.css';

import Header from './Header';
import Map from './Map';
import Frame from './Frame';
import WallOfCards from './WallOfCards';

import mapboxgl from 'mapbox-gl';

import { connect } from 'react-redux'

import { State } from './ReducerTypes';


interface IProps {

}

interface StateProps {

}

interface IState {

}

type Props = StateProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {
  
  return {  };
}

class App extends React.Component <Props,IState> {

  constructor(props: Props) {

    super(props);

    this.keyDown = this.keyDown.bind(this);
  }

  keyDown(event: any) {

    console.log("keyDown: "+event);

    if(event.key === "Escape"){
        
    }
  }

  componentDidMount(){

    //document.addEventListener("keydown", this.keyDown, false);
  }
  
  render() {

    return (
      <div className="App">

        <Header />
        
        <Map />

        <WallOfCards />

        <Frame />

        
        
      </div>
    );
  }
}

export default connect(mapStateToProps)(App)

