import React from 'react';
import './Frame.css';

import { connect } from 'react-redux'

import { State } from './ReducerTypes';

import { closeFrame } from './ReducerActions';

interface IProps {

}

interface IState {
  
  messageName: string;
  messageEmail: string;
  messageTitle: string;
  messageText: string;
}

interface DispatchProps {
    closeFrame: () => void;
}

interface StateProps {
    
    frame_url: string;
}

type Props = DispatchProps & StateProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
        frame_url: state.frame_url
    };
}

const mapDispatchToProps = {

    closeFrame
};


class Frame extends React.Component <Props,IState> {

  private scrollTimer: number;
  
  constructor(props: Props) {

    super(props);

    this.closeFrame = this.closeFrame.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.changeMessageName = this.changeMessageName.bind(this);
    this.changeMessageEmail = this.changeMessageEmail.bind(this);
    this.changeMessageTitle = this.changeMessageTitle.bind(this);
    this.changeMessageText = this.changeMessageText.bind(this);

    this.onBlurInput = this.onBlurInput.bind(this);
    this.onFocusInput = this.onFocusInput.bind(this);

    this.scrollTimer = 0;

    this.state = {
      messageName: '',
      messageEmail: '',
      messageTitle: '',
      messageText: ''
    };
  }

  componentDidMount () {

    
  }

  closeFrame() {

    this.props.closeFrame();
  }

  changeMessageName(event: any) {

    this.setState({ messageName: event.target.value });
  }

  changeMessageEmail(event: any) {

    this.setState({ messageEmail: event.target.value });
  }

  changeMessageTitle(event: any) {

    this.setState({ messageTitle: event.target.value });
  }

  changeMessageText(event: any) {

    this.setState({ messageText: event.target.value });
  }

  scrollWindow() {

    window.scrollTo(0,0);
  }

  onBlurInput(event: any) {

    this.scrollTimer = window.setTimeout(() => this.scrollWindow(), 100);

  }

  onFocusInput(event: any) {

    if (this.scrollTimer) {

      clearTimeout(this.scrollTimer);
      this.scrollTimer = 0;
    }
  }

  sendMessage() {

    if (!this.state.messageName || (this.state.messageName.length == 0))
      return;
    if (!this.state.messageEmail || (this.state.messageEmail.length == 0))
      return;
    if (!this.state.messageTitle || (this.state.messageTitle.length == 0))
      return;
    if (!this.state.messageText || (this.state.messageText.length == 0))
      return;

    fetch('send_message.php', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.state.messageName,
        email: this.state.messageEmail,
        title: this.state.messageTitle,
        text: this.state.messageText})
    })
      .then(res => res.json())
      .then(json => {this.closeFrame()});
  }

  renderFrameContent() {

    if (this.props.frame_url == 'contact') {

      return (<div className="ContactForm"><div className="ContactTitle">contact me & maps</div><input type="text" placeholder="name" onChange={this.changeMessageName} onFocus={this.onFocusInput} onBlur={this.onBlurInput}/><input type="text" placeholder="email" onChange={this.changeMessageEmail} onFocus={this.onFocusInput} onBlur={this.onBlurInput}/><input type="text" placeholder="title" onChange={this.changeMessageTitle} onFocus={this.onFocusInput} onBlur={this.onBlurInput}/><textarea placeholder="enter your message" rows={7} onChange={this.changeMessageText} onFocus={this.onFocusInput} onBlur={this.onBlurInput}></textarea><div className="SendMessage" onClick={this.sendMessage}/></div>);
    }
    else {

      return (<iframe src={this.props.frame_url}></iframe>);
    }
  }

  render() {
    
    return (<div className={"Frame "+ ((this.props.frame_url.length == 0)?"FrameHidden":"")}><div className="CloseFrame" onClick={this.closeFrame}/><div className={"FrameContainer "+ ((this.props.frame_url.length == 0)?"FrameContainerClosed":"FrameContainerOpened")}>{this.renderFrameContent()}</div></div>);
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Frame);
