import React from 'react';
import * as Redux from 'redux'
import { connect } from 'react-redux'

import { ActionTypes } from './ReducerTypes';
import { changeMap } from './ReducerActions';

import { State } from './ReducerTypes';

import './Map.css';

interface IProps {
  
}

interface IState {
  
}

interface DispatchProps {
    changeMap: (url: string) => void;
}

interface StateProps {
    
    map_url: string;
}

type Props = StateProps & DispatchProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
        map_url: state.map_url
    };
}

const mapDispatchToProps = {

    changeMap
};

interface IState {
    
}

class Map extends React.Component <Props,IState> {

  constructor(props: Props) {

    super(props);
    
  }

  componentDidMount () {

  }
  
  render() {

    return (
      <iframe className="Map" src={this.props.map_url}></iframe>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Map);