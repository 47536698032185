import React from 'react';
import './Header.css';

import { connect } from 'react-redux'

import { openFrame } from './ReducerActions';

import { State } from './ReducerTypes';

interface IProps {

}

interface IState {

  menuOpened: boolean;
}

interface DispatchProps {
    openFrame: (url: string) => void;
}

interface StateProps {
    
}


type Props = DispatchProps & StateProps & IProps;

function mapStateToProps(state: State, ownProps: IProps): StateProps {

    return {
        
    };
}

const mapDispatchToProps = {

    openFrame
};

class Header extends React.Component <Props,IState> {

  constructor(props: Props) {

    super(props);

    this.reload = this.reload.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.openTweetMyMap = this.openTweetMyMap.bind(this);
    this.openEditor = this.openEditor.bind(this);
    this.openTwitter = this.openTwitter.bind(this);
    this.openAbout = this.openAbout.bind(this);
    this.openContact = this.openContact.bind(this);

    this.state = {

      menuOpened: false

    };
  }

  reload() {

    window.location.reload(true); 
  }

  openMenu() {

    this.setState({ menuOpened: !this.state.menuOpened });
  }

  openTweetMyMap() {

    window.open("https://www.tweetmymap.com", "_blank");

    this.setState({ menuOpened: false });
  }

  openEditor() {

    window.open("https://maps.meandmaps.com/editor", "_blank");

    this.setState({ menuOpened: false });
  }

  openTwitter() {

    window.open("https://twitter.com/meandmaps", "_blank");

    this.setState({ menuOpened: false });
  }

  openAbout() {

    this.props.openFrame("about.html");

    this.setState({ menuOpened: false });
  }

  openContact() {

    this.props.openFrame("contact");

    this.setState({ menuOpened: false });
  }
  
  render() {

    return (
      <div className="Header">
      
        <div className="title">

          <div className="logo" onClick={this.reload}></div>
          <span className="meandmaps">me & maps</span>
          <span className="subtitle">maker of interactive cartography</span>

        </div>

        <div id="twitter" onClick={this.openTwitter}></div>

        <ul className="menu">

          <li className="openMenu" onClick={this.openMenu}></li>
          <li className={((this.state.menuOpened)?"itemMenuOpened":"itemMenu")} onClick={this.openTweetMyMap}>tweetmymap</li>
          <li className={((this.state.menuOpened)?"itemMenuOpened":"itemMenu")} onClick={this.openEditor}>editor</li>
          <li className={((this.state.menuOpened)?"itemMenuOpened":"itemMenu")} onClick={this.openAbout}>about</li>
          <li className={((this.state.menuOpened)?"itemMenuOpened":"itemMenu")} onClick={this.openContact}>contact</li>

        </ul>

      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Header);
